(function(Grid) {
    'use strict';

    Grid.selector = '.js-grid';

    Grid.reload = function(element) {
        $(element).masonry('reloadItems').masonry();
    };

    Grid.init = function(element) {
        return $(element).masonry({
            itemSelector:       '.js-grid-item',
            columnWidth:        '.js-grid-sizer',
            percentPosition:    true,
            transitionDuration: 0
        });
    };

    Grid.initInfiniteScroll = function(element) {
        return $(element).infiniteScroll({
            path:         'page{{#}}',
            loadOnScroll: false
        });
    };
})(window.Way2web.Grid = window.Way2web.Grid || {});
