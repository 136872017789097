(function(Clones) {
    'use strict';

    // Object with the elements for the clones.
    Clones.selector = '.js-clone';

    Clones.items = [];

    // Search for the elements to clone.
    Clones.init = function() {
        $(Clones.selector).each(Clones.find);
    };

    // Attach to the clone function.
    Clones.find = function() {
        Clones.items.push(
            new Clone(
                $(this),
                {},
                {
                    hidden: 'is-hidden'
                }
            )
                .init()
        );
    };
})(window.Way2web.Clones = window.Way2web.Clones || {});
