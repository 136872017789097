// https://tc39.github.io/ecma262/#sec-array.prototype.find
/* eslint-disable vars-on-top */
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function(predicate) {
            var kValue;

            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var obj = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = obj.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var key = 0;

            // 6. Repeat, while k < len
            while (key < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return kValue.
                kValue = obj[key];

                if (predicate.call(thisArg, kValue, key, obj)) {
                    return kValue;
                }
                // e. Increase k by 1.
                key++;
            }

            // 7. Return undefined.
            return undefined;
        }
    });
}
/* eslint-enable vars-on-top */
