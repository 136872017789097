(function(Dropdown) {
    'use strict';

    Dropdown.selector = '.js-dropdown';
    Dropdown.trigger = '.js-dropdown-trigger';
    Dropdown.activeClass = 'is-active';

    Dropdown.init = function() {
        $(Dropdown.selector)
            .find(Dropdown.trigger)
            .on('click', Dropdown.toggle);

        $(document).on('click', Dropdown.closeAll);
    };

    Dropdown.toggle = function(event) {
        var parent = $(this).parents(Dropdown.selector);

        event.stopPropagation();

        parent.toggleClass(Dropdown.activeClass);
    };

    Dropdown.closeAll = function() {
        $(Dropdown.selector).removeClass(Dropdown.activeClass);
    };
})(window.Way2web.Dropdown = window.Way2web.Dropdown || {});
