(function(Helpers) {
    'use strict';

    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    //
    // source: http://snippetrepo.com/snippets/basic-vanilla-javascript-throttlingdebounce

    /**
     * Debounce a function.
     *
     * @param {object} func
     * @param {number} wait
     * @param {boolean} immediate
     *
     * @return {object}
     */
    Helpers.debounce = function(func, wait, immediate) {
        var timeout;

        return function() {
            var context = this;
            var args = arguments;

            clearTimeout(timeout);
            timeout = setTimeout(function() {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            }, wait);
            if (immediate && !timeout) {
                func.apply(context, args);
            }
        };
    };

    /**
     * Get the language.
     *
     * @return {string}
     */
    Helpers.getLanguage = function() {
        var language = $('.js-language').attr('lang');

        if (typeof language === 'undefined' || language === '') {
            throw 'language code missing';
        }

        return language;
    };

    /**
     * Remove the first character of a string.
     *
     * @param {string} selector
     *
     * @return {string}
     */
    Helpers.removeSelectorPrefix = function(selector) {
        var isClass = selector.charAt(0) == '.';
        var isId = selector.charAt(0) == '#';

        if (isClass || isId) {
            return selector.substring(1);
        }
    };

    /**
     * Add the class prefix (.) to a string.
     *
     * @param {string} selector
     *
     * @return {string}
     */
    Helpers.addClassPrefix = function(selector) {
        return '.' + selector;
    };

    /**
     * Fix the ajax url for the stubbing data from Laravel for localhost and
     * the compiled templates from the filesystem by returning the local dev url.
     *
     * @param {string} route
     *
     * @return {string}
     */
    Helpers.route = function(route) {
        var isLocalhost = window.location.hostname == 'localhost';
        var isFilesystem = window.location.hostname == '';

        if (isLocalhost || isFilesystem) {
            return 'http://desapp.localtest.me' + route;
        }

        // Older IE versions then IE11 and an old version of IE11 dont have access
        // to window.location.origin so we need to add it.
        if (!window.location.origin) {
            window.location.origin = window.location.protocol + '//' + window.location.host;
        }

        return window.location.origin + route;
    };
})(window.Way2web.Helpers = window.Way2web.Helpers || {});
