(function(Way2web) {
    'use strict';

    Way2web.onDomReady = function() {
        Way2web.setCSRFToken();
        Way2web.initSelectFieldToggle();
        Way2web.Dropdown.init();
        Way2web.Clones.init();

        Way2web.form = new W2Form().init();

        $('.js-select2').each(Way2web.Select2.init);

        Way2web.showSearchKeyword();
    };

    // @TODO: should be replaced by Laravel.
    Way2web.showSearchKeyword = function() {
        var query = new URLSearchParams(window.location.search);

        $('.js-show-keyword').val(query.get('initKeyword'));
    };

    /**
     * Set the CSRF token in ajax calls.
     */
    Way2web.setCSRFToken = function() {
        var token = $('meta[name="csrf-token"]').attr('content');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': token
            }
        });
    };

    /**
     * Toggles form fields based on the selected value.
     *
     * Add the class 'js-select-toggle' to the select, 'js-select-toggle-field' to a possible toggle field.
     *
     * Add the 'data-select-values=""' data attribute with in it, the value(s) it needs to be visible for.
     * For example: <div class="form group | js-select-toggle-field" data-select-values='["admin","company-admin"]'>
     */
    Way2web.initSelectFieldToggle = function() {
        var elemSelect = $('.js-select-toggle');
        var possibleFields = $('.js-select-toggle-field');

        var processFields = function() {
            var selectedValue = elemSelect.val();

            possibleFields.hide();

            if (selectedValue.length == 0) {
                return;
            }

            possibleFields.each(function() {
                var field = $(this);
                var fieldToggleValues = field.data('select-values');

                if(fieldToggleValues.indexOf(selectedValue) !== -1) {
                    field.show();
                }
            });
        };

        if(elemSelect.length == 0) {
            return;
        }

        elemSelect.on('change', function() {
            processFields();
        });

        processFields();
    };
})(window.Way2web = window.Way2web || {});

/**
 * A wrapper around the trans() function, so we can be consistent between the front-end and the back-end code.
 *
 * @param {string} string
 * @param {object} args
 *
 * @return {string}
 */
window.trans = function(string, args) {
    return Lang.get(string, args);
};

/**
 * A wrapper around the Lang.choice() function, so we can be consistent between the front-end and the back-end code.
 *
 * @param  {string} string
 * @param  {number} amount
 * @param  {object} args
 *
 * @return {string}
 */
window.trans_choice = function(string, amount, args) {
    return Lang.choice(string, amount, args);
};

$(document).ready(window.Way2web.onDomReady);
