(function(Select2) {
    'use strict';

    Select2.formatter = {
        /**
         * When loading return the "searching..." label or return a group label
         *
         * @param {object}  data
         * @return {string}
         */
        result: function(data) {
            if (data.loading || data.text) {
                return data.text;
            }

            return data.label;
        },

        /**
         * Searches for a selection attribute.
         * If that is not present, if falls back to the label.
         *
         * Return the placeholder if no option is selected.
         *
         * @param {object}  data
         * @return {string}
         */
        selection: function(data) {
            if (data.text) {
                return data.text;
            }

            if (data.selection) {
                return data.selection;
            }

            return data.label;
        }
    };

    Select2.defaultOptions = {
        allowClear:  true,
        placeholder: false,
        theme:       'classic'
    };

    /**
     * Scope: loads data from external source and format it very basicly.
     * What it doesn't do: load slected options via ajax on page load.
     */
    Select2.ajaxOptions = {
        ajax: {
            dataType: 'json',
            type:     'POST',
            delay:    250,
            data:     function(term) {
                return term;
            },
            processResults: function(data) {
                return {
                    results: data.items
                };
            }
        },
        escapeMarkup: function(markup) {
            return markup;
        },
        templateResult:     Select2.formatter.result,
        templateSelection:  Select2.formatter.selection,
        minimumInputLength: 3
    };

    /**
     * Initialize the select2 function, for nice select elements.
     */
    Select2.init = function() {
        var select = $(this);
        var options = Select2.defaultOptions;

        Select2.placeholder = select.data('placeholder') || select.data('data-placeholder');

        if (typeof Select2.placeholder === 'undefined' || Select2.placeholder === '') {
            throw 'A data-placeholder value is needed on the select2 element';
        }

        if (select.data('ajax--url')) {
            $.extend(options, Select2.ajaxOptions);
        }

        select.select2(options);
    };
})(window.Way2web.Select2 = window.Way2web.Select2 || {});
