/* eslint-disable no-console */
(function(Api) {
    'use strict';

    /**
     * Default pagination properties.
     */
    Api.paginationObject = {
        total:           0,
        totalPages:      1,
        currentPage:     1,
        baseUrl:         null,
        currentPageUrl:  null,
        previousPageUrl: null,
        nextPageUrl:     null
    };

    /**
     * Show a failed network request error to the user.
     * Idea from: https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
     *
     * @param {object} error The JavaScript error message;
     */
    Api.error = function(error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error.message);
        }

        console.error(error.config);
    };

    /**
     * Reset the pagination properties with the new properties from the Laravel
     * request.
     *
     * @param {object} response The laravel response object.
     *
     * @return {string} The new pagination properties.
     */
    Api.getPagination = function(response) {
        var pagination = Api.paginationObject;
        var meta = response.data.meta;
        var links = response.data.links;

        pagination.total = meta.total;
        pagination.totalPages = meta.last_page;
        pagination.currentPage = meta.current_page;
        pagination.baseUrl = meta.path;

        /**
         * @TODO: Fix this current page url.
         */
        // pagination.currentPageUrl = response.meta.path;

        pagination.previousPageUrl = links.prev;
        pagination.nextPageUrl = links.next;

        return pagination;
    };

    /**
     * Convert the items from Laravel to standardized JavaScript "models".
     *
     * @param {object} response The laravel response object.
     * @param {object} Model The import object of the model.
     *
     * @return {array} A list with all the items as a "model".
     */
    Api.dataToModel = function(response, Model) {
        if (Array.isArray(response)) {
            return response.map(function(item) {
                return new Model(item);
            });
        }

        return new Model(response);
    };
})(window.Way2web.Api = window.Way2web.Api || {});
/* eslint-enable */
