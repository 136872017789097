(function(Way2web) {
    'use strict';

    /*
        these are the inuit breakpoints
        in order to use them you need a tool that can check if a mediaquery is active

        for example verge (bower install verge --save):

        if(verge.mq(Way2web.config.breakpoints.palm)){
            console.log("YES! Palm is active.");
        }
    */
    var palm = 'screen and (max-width: 44.9375em)';
    var lap = 'screen and (min-width: 45em) and (max-width: 63.9375em)';
    var lapAndUp = 'screen and (min-width: 45em)';
    var portable = 'screen and (max-width: 63.9375em)';
    var desk = 'screen and (min-width: 64em)';
    var deskWide = 'screen and (min-width: 95em)';
    var retina = '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)';

    /**
     * Settings for the JavaScript functions.
     *
     * @type {object}
     */
    Way2web.config = {
        breakpoints: {
            palm:     palm,
            lap:      lap,
            lapAndUp: lapAndUp,
            portable: portable,
            desk:     desk,
            deskWide: deskWide,
            retina:   retina
        },
        classes: {
            success: 'has-success',
            error:   'has-error',
            hidden:  'hidden'
        },
        format: {
            'date_carbon':     'YYYY-MM-DD',
            'date_moment':     'DD-MM-YYYY',
            'time_moment':     'HH:mm',
            'datetime_moment': 'DD-MM-YYYY HH:mm'
        },
        constants: {}
    };
})(window.Way2web = window.Way2web || {});
